import useIframeNavigation from './hooks/useIframeNavigation'; // Adjust path

const Welcome = () => {
  useIframeNavigation(); // Use the hook

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        margin: "0",
        padding: "0",
        overflow: "hidden",
      }}
    >
      <iframe
        src="/webflow/index.html"
        title="Landing Page"
        width="100%"
        height="100%"
        style={{ border: "none" }}
      />
    </div>
  );
};

export default Welcome;

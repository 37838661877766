import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../util/api'; // Import the custom Axios instance

import Account from '../components/Account';
import Meeting from '../components/Meeting';

import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import NotesIcon from '@material-ui/icons/Notes';
import Avatar from '@material-ui/core/Avatar';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    marginLeft: drawerWidth
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  avatar: {
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
    marginTop: 20
  },
  uiProgress: {
    position: 'fixed',
    zIndex: '1000',
    height: '31px',
    width: '31px',
    left: '50%',
    top: '35%'
  },
  toolbar: theme.mixins.toolbar
}));

const Home = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [render, setRender] = useState(false);
  const [schoolName, setSchoolName] = useState('');
  const [profilePicture, setProfilePicture] = useState('');
  const [uiLoading, setUiLoading] = useState(true);

  const loadAccountPage = useCallback(() => setRender(true), []);
  const loadMeetingPage = useCallback(() => setRender(false), []);
  const logoutHandler = useCallback(() => {
    sessionStorage.removeItem('AuthToken');
    navigate('/login');
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      const authToken = sessionStorage.getItem('AuthToken');
      if (!authToken) {
        navigate('/login');
        return;
      }

      try {
        api.defaults.headers.common['Authorization'] = authToken;
        const response = await api.get('/user', {          
          timeout: 10000, // Fail fast if slow
        });
        const { schoolName, imageUrl } = response.data.userCredentials;

        setSchoolName(schoolName);
        setProfilePicture(imageUrl);
        setUiLoading(false);
      } catch (error) {
        console.error('Fetch error:', error);
        if (error.response?.status === 403) {
          navigate('/login');
        }
        setUiLoading(false); // Show error UI instead of hanging
      }
    };

    fetchData();
  }, [navigate]);

  if (uiLoading) {
    return (
      <div className={classes.root}>
        <CircularProgress size={80} className={classes.uiProgress} />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} style={{ backgroundColor: '#3ab09e' }}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            ONTOMO　｜　オントモ
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{ paper: classes.drawerPaper }}
      >
        <div className={classes.toolbar} />
        <Divider />
        <center>
          <Avatar src={profilePicture} className={classes.avatar} variant="square" />
          <p>{schoolName}</p>
        </center>
        <Divider />
        <List>
          <ListItem button onClick={loadMeetingPage}>
            <ListItemIcon><NotesIcon /></ListItemIcon>
            <ListItemText primary="Meeting" />
          </ListItem>
          <ListItem button onClick={loadAccountPage}>
            <ListItemIcon><AccountBoxIcon /></ListItemIcon>
            <ListItemText primary="Account" />
          </ListItem>
          <ListItem button onClick={logoutHandler}>
            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      <div>{render ? <Account /> : <Meeting />}</div>
    </div>
  );
};

export default Home;

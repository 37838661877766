import axios from 'axios';

const isDev = process.env.NODE_ENV === 'development';
const baseURL = isDev
  ? process.env.REACT_APP_BASE_URL_API_DEV || 'http://localhost:5001/paul-1904c/us-central1/api'
  : process.env.REACT_APP_BASE_URL_API_PRD || 'https://us-central1-paul-1904c.cloudfunctions.net/api';

const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

export default api;
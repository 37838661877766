import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useIframeNavigation = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleIframeNavigation = (event) => {
      if (event.data.type === 'navigate' && event.data.path) {
        navigate(event.data.path);
      }
    };

    window.addEventListener('message', handleIframeNavigation);
    return () => window.removeEventListener('message', handleIframeNavigation);
  }, [navigate]);
};

export default useIframeNavigation;